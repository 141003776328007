<template>
  <div class="ac_box">
    <div class="article_box">
      <Header :atIndex='index' class="header"></Header>
      <!-- 文章背景 -->
      <div class="article_bacimg" ref="img_back"><img :src="backUrl" alt=""></div>
      <!-- 二级背景 -->
      <div class="erjiBack"></div>
    </div>
    <div class="acticle_w">
      <div class="_top">
        <div class="imge">
          <img src="../../assets/sem_2.png" alt="">
          <span class="spans">{{title}}</span>
        </div>
        <div class="breadcrumb" @click="pushBaeadcrumb">
          <span data-index="0">首页 ></span>
          <span v-if="routeData.title1" data-index="1">{{routeData.title1}}</span>
          <span v-if="routeData.title2" data-index="2">{{ ' > ' +routeData.title2}}</span>
          <span v-if="routeData.title3" data-index="3">{{ ' > ' +routeData.title3}}</span>
          <span v-if="routeData.title4" data-index="4">{{ ' > ' +routeData.title4}}</span>
        </div>
      </div>
      <div class="nav">
        <div class="left_nav" ref="left_nav">
          <ul ref="ull">
            <li v-for="(item,i) in submenus_.subdata" :key="item.id" @click="on_height(item,i)">
              <a></a>
              <span class="route_dh">{{item.title}}</span>
              <em v-if="item.children" class="xzElement"></em>
              <div v-if="item.children" ref="twoMenu">
                <div :class="item1.id === 282?'twoMenu_overflow':'twoMenu'" v-for="(item1,ii) in item.children" :key="ii" @click.stop="erjiclick(item,item1,i,ii)">
                  <span>{{item1.title}}</span>
                </div>
              </div>
            </li>
          </ul>
        </div>
        <div class="right_nav">
          <keep-alive>
            <router-view />
          </keep-alive>
        </div>
      </div>
    </div>
    <Footer class="footer" />
  </div>
  <!-- 移动端二级页面 -->
  <div class="phoneRouterview" v-if="show_phone">
    <phoneHeader />
    <div class="article_bacimg"><img :src="backUrl" alt=""></div>
    <div class="navphone">
      <div class="leftImg"><span>{{title}}</span></div>
      <div class="rightText" @click="pushBaeadcrumb">
        <span data-index="0">首页 ></span>
          <span v-if="routeData.title1" data-index="1">{{routeData.title1}}</span>
          <span v-if="routeData.title2" data-index="2">{{ ' > ' + routeData.title2}}</span>
          <span v-if="routeData.title3" data-index="3">{{ ' > ' + routeData.title3}}</span>
          <span v-if="routeData.title4" data-index="4">{{ ' > ' + routeData.title4}}</span>
      </div>
    </div>
    <div class="routerNav">
      <router-view></router-view>
    </div>
    <phoneFooter style="margin-top:calc(50px)"/>
  </div>
</template>
<script setup>
import Header from '@/components/header.vue'
import Footer from '@/components/footer.vue'
import phoneHeader from '@/components/headerPhone.vue'
import phoneFooter from '@/components/footerPhone.vue'
import { getNavlist_id,get_home_list,getBannernav_list } from '@/api/API.js'
import {ref,reactive,onMounted,watch,nextTick,onUnmounted} from 'vue'
import { useRouter,useRoute } from 'vue-router'
import base64_ from '@/hooks/useBase64text.js'
let router = useRouter()
let route = useRoute()
const baseUrl = process.env.VUE_APP_BASE_URL + '/'
let routeData = ref(JSON.parse(base64_.decode(route.params.obj)))

const submenus_ = reactive({
  subdata:[],
})
const title = ref("加载中...")
const id = ref(routeData.value.id)
let flagOne = ref(true)
let left_nav = ref(null)
let ull = ref(null)
let flag = ref(true)
let backUrl = ref('')
let img_back = ref(null)

let show_phone = ref(false)
// 判断窗口大于750就不创建移动端结构函数，以免路由数据多次渲染
const getphoneWidht = () => {
  let widthWind = window.innerWidth
  sizeWidth()
  window.addEventListener("resize",() => {
    widthWind = window.innerWidth
    sizeWidth()
  })
    function sizeWidth(){
      if(widthWind < 750){
      show_phone.value = true
    }else{
      show_phone.value = false
    }
    }
}


// type为1就是home分类的侧边栏数据
let getHomtlist = async (index) => {
  img_back.value.style.opacity = '0'
  const { data: res } = await get_home_list()
  if (res.code == 200) {
    submenus_.subdata = res.data
    // 根据穿过来的index 给图片赋值
    if (index!=-1&&res.data[index].url) {
      img_back.value?img_back.value.style.opacity = '1':''
      backUrl.value = baseUrl + res.data[index].url
    } else{
        // 判断该导航如果没有图片就给个默认的
      img_back.value.style.opacity = '1'
      backUrl.value = 'https://dmimage.obs.cn-north-4.myhuaweicloud.com/2022061015506eY882b'
      }
  }
}
// 根据id获取导航栏下方入口侧边栏数据
let getBannerNavlist = async (id) => {
  img_back.value.style.opacity = '0'
  const { data: res } = await getBannernav_list(id)
  if (res.code == 200) {
      submenus_.subdata = res.data[0]?res.data[0].children:[]
    // 根据穿过来的index 给图片赋值
      if(res.data[0].url){
      img_back.value?img_back.value.style.opacity = '1':''
      backUrl.value = baseUrl + res.data[0].url
      }else{
        // 判断该导航如果没有图片就给个默认的
      img_back.value?img_back.value.style.opacity = '1':''
      backUrl.value = 'https://dmimage.obs.cn-north-4.myhuaweicloud.com/2022061015506eY882b'
  }
}
}
// 点击侧边导航的一级菜单
let on_height = (item, i) => {
   // 如果有外部链接就跳转
  if(item.external_url){
    window.open(item.external_url)
    return
  }
  // 如果template_type就默认跳转子菜单第一个
  if(item.template_type == 1){
    router.push({
    name:item.vue_url,
    params:{obj:base64_.encode(JSON.stringify(
      {
        // title1: item.title,
        title1: title.value,
          title2:item.children[0].title,
          id:item.pid,
          index:i,
          flag:1,
          article_id:item.id==34?55:item.children[0].id,
          }
    ))}
    })
    return
  }
  if(item.is_banner_nav == 2){
  router.push({
    name:item.vue_url,
      params:{obj:base64_.encode(JSON.stringify({
          title1:title.value,
          title2:item.title,
          type_id:item.id,
          index:i,
          isBanner_nav_id:item.is_banner_nav,
          id:item.pid,
          article_id:item.id,
          }))}
    })
  return
  }
  else if(item.is_banner_nav == 1){
  router.push({
    name:item.vue_url,
      params:{obj:base64_.encode(JSON.stringify({
          title1:title.value,
          title2:item.title,
          id:item.pid,
          index:i,
          flag:1,
          article_id:item.id==34?55:item.id,
          }))}
    })
  }
  else {
     router.push({
        name:item.vue_url,
        params:{obj:base64_.encode(JSON.stringify({
          title1:item.title,
          type_id:item.id,
          index:i,
          }))}
    })
   }
}
// 点击侧边导航的二级菜单
let erjiclick = (pritem,item,i,ind) => {
   // 如果有外部链接就跳转
  if(item.external_url){
    window.open(item.external_url)
    return
  }
   router.push({
    name:item.vue_url,
    params:{obj:base64_.encode(JSON.stringify({
          title1:title.value,
          title2:pritem.title,
          title3:item.title,
          id:id.value,
          level:item.level,
          id4:item.children?item.children[0].id:'',
          i4:0,
          index:i,
          active:ind,
          article_id:item.id,
          type_id:item.id,
          isBanner_nav_id:item.is_banner_nav
          }))}
    })
}

// 点击导航栏激活对应的左侧菜单栏   
onMounted(() => { 
  auNavmenu()
  getphoneWidht()
  // 监听动画执行完毕就显示侧边导航栏
  left_nav.value.addEventListener("animationend",() => {
    left_nav.value.style.opacity = '1'
  })
  // 进页面就监听路由的变化，请求数据 和选中点进来的li元素  
  watch(() => route.params, () => {
    if(!route.params.obj) return
    document.documentElement.scrollTop = 0
    route.params.obj?routeData.value = JSON.parse(base64_.decode(route.params.obj)):''
    auNavmenu() 
  }, {deep: true })
  
})

document.documentElement.scrollTop = 0

let auNavmenu = () => {
   id.value = routeData.value.id?routeData.value.id:0
   title.value = routeData.value.title1?routeData.value.title1:''
  // 判断type_id为真的话就获取home模块的侧边栏数据否则 
  routeData.value.type_id&&!routeData.value.isBanner_nav_id?getHomtlist(routeData.value.index):getBannerNavlist({id:routeData.value.id})
}
// 监听左侧菜单栏数据变化后，就高亮对应菜单栏
watch(() => submenus_.subdata, () => {
  // 传入参数调用函数，高亮左侧菜单栏
  _active(
    routeData.value.index != -1 ? routeData.value.index : -1,
    routeData.value.active || routeData.value.active == 0 ? routeData.value.active : undefined
    )
})

let _active = (ind,childrenIndex) => {
  nextTick(_ => {
    clear_active()
  if(ull.value && ind != -1){
     let LiHeight = ull.value.children[ind]
    LiHeight?LiHeight.children[1].style.color = '#174994':''
    LiHeight?LiHeight.children[0].style.left = '1px':''
    LiHeight?LiHeight.children[0].style.opacity = '1':''
  if(LiHeight?LiHeight.children[3]:''){
    let sublength = LiHeight.children[3].children.length
    // LiHeight.style.height = ((sublength * 40) + 70) / 128 + 'rem'
    LiHeight.style.height = ((sublength * 40) + 80) / 128 + 'rem'
    // 动态给left_nav盒子设置高度 高度为所有li标签的高度加上子盒子二级菜单展开的高度
    left_nav.value.style.height = ((ull.value.children.length * 80) + ((sublength * 40) + 60)) / 128 + 'rem'
    for(let x = 0; x < LiHeight.children[3].children.length;x++){
       ull.value.children[ind].children[3].children[x].style.color = ''
       ull.value.children[ind].children[3].children[x].style.margin = '10px 0'
    }
     // 判断当前li下面是否有em标签，有设置值样式
    LiHeight.children[2] ? LiHeight.children[2].style.color = '#174994':''
    LiHeight.children[2]?LiHeight.children[2].style.transform = 'rotate(90deg)':''
    childrenIndex || childrenIndex == 0 ? ull.value.children[ind].children[3].children[childrenIndex].style.color = '#174994':''
  }
  }
  })
}

let clear_active = () => {
  // 动态给left_nav盒子设置高度 高度为所有li标签的高度
  left_nav.value.style.height = (ull.value.children.length * 80) / 128 + 'rem'
  //每次鼠标移入li先把上一次的样式清空 然后再从新赋值
  for(let j=0;j<ull.value.children.length;j++){
    ull.value.children[j].children[0].style.left = -10/128 + 'rem'
    ull.value.children[j].children[0].style.opacity = '0'
    ull.value.children[j].children[1].style.color = ''
    ull.value.children[j].style.height = 60 / 128 + 'rem'
    ull.value.children[j].children[2]?ull.value.children[j].children[2].style.transform = 'rotate(0)':''
  }
}
onUnmounted(() => {
  window.sessionStorage.removeItem("params")
  window.sessionStorage.setItem("page",'1')
})
// 点击面包屑（事件委托）
let pushBaeadcrumb = (e) => {
  const span_index = e.target.getAttribute("data-index")
  if(span_index == '0'){
    router.push("/")
    return
  }
  router.go(-1)
  
  // switch(span_index){
  //   case '0':
  //   router.push("/")
  //   break;
  //   case '1':
  //   router.go(-1)
  //   break;
  //   case '2':
  //   router.go(-1) 
  //   break;
  //   case '3':
  //   router.go(-1) 
  //   break;
  //   case '4':
  //   router.go(-1)
  //   break;
  // }

}


</script>

<style lang="less" scoped>
@maxw:128rem;
@maxw_:750/100vw;
.ac_box{
  width: 1920/@maxw;
  background-color: #fff;
  margin: 0 auto;
  padding: 0;
  box-sizing: border-box !important;
  overflow: hidden;
  .article_box{
  position: relative;
  padding: 0;
  width: 100%;
  height: 650/@maxw;
  background-color: #174994;
  .header{
    position:absolute;
    top: 0;
    left: 0;
    z-index: 9999;
  }
  .article_bacimg{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transition: all .8s !important;
    img{width: 100%;height: 100%;}
  }
  .erjiBack{
    position: absolute;
    background-image: url("../../assets/sem.png");
    background-size: 100% 100%;
    position: absolute;
    // bottom: -252/@maxw;
    bottom: -253/@maxw;
    width: 15rem;
    height: 517/@maxw;
  }
}
   .acticle_w{
    position: relative;
    top: -85/@maxw;
    padding: 0;
    margin: 0 auto;
    width: 1588/@maxw;
    z-index: 9999;
    ._top{
      display: flex;
      justify-content: space-between;
      width: 100%;
      height: 85/@maxw;
      .imge{
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        animation: pageYs 1s ease;
        width: 382/@maxw;
        line-height: 85/@maxw;
        font-size: 36/@maxw;
        font-family: Source Han Sans CN-Medium;
        font-weight: 500;
        color: #FFFFFF;
        text-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
        text-align: center;
        // top: -10/@maxw;
        top: -9/@maxw;
        height: 94/@maxw;
        img{position: absolute;top: 0;left: 0;width: 100%;height: 100%;opacity:1;z-index: -999;}
        >span{width: 100%; height: 100%;}
      }
      .breadcrumb{
        display: flex;
        line-height: 85/@maxw;
        text-align: center;
        // height: 100%;
        cursor: pointer;
        margin-right: 20/@maxw !important;
        span{
        margin:0 0 0 10/@maxw;
        font-size: 22/@maxw;
          font-family: Source Han Sans CN-Medium;
          font-weight: 500;
          color: #FFFFFF;
          height: 60/@maxw;
        }
      }
    }
    .nav{
      display: flex;
      justify-content: space-between;
      width: 100%;
      .left_nav{
        position:relative;
        box-sizing: border-box;
        width: 382/@maxw;
        min-height:730/@maxw;
        background-color: #ededed;
        padding: 0 15/@maxw 15/@maxw 45/@maxw;
        animation: pageYs 1s ease .3s;
        transition: ease .8s;
        opacity: 0;
        cursor: pointer;
        font-size: 18/@maxw;
        >ul{
          width: 100%;
          margin: 0;padding: 0;
          font-size: 23/@maxw;
          font-family: Source Han Sans CN-Medium;
          font-weight: 500;
          color: #6E6E6E;
          transition: ease .5s;
          animation: pageYs .5s ease;
          >li{
            position: relative;
            z-index: 9999;
            overflow: hidden;
            text-align: left;
            line-height: 77/@maxw;
            background-image: url("../../assets/zhixian_.png");
            background-position:bottom;
            background-repeat: no-repeat;
            width: 100%;
            height: 60/@maxw;
            margin-top: 5/@maxw;
            padding-bottom: 10/@maxw;
            transition: ease .5s;
            font-size: 26/@maxw;
            font-family: Source Han Sans CN-Medium;
            font-weight: 500;
            color: #6E6E6E;
            a{
              position:absolute;
              // top: 27/@maxw;
              // top: 22/@maxw;
              top: 23/@maxw;
              width: 5/@maxw;
              height: 21/@maxw;
              left: -10/@maxw;
              // line-height:23/@maxw;
              background-image: url("../../assets/left_nav_back.png");
              background-size: 100% 100%;
              background-repeat: no-repeat;
              // background: linear-gradient(118deg, #174994 0%, #387DE2 100%);
              opacity: 0;
              transition: all .3s;
            }
            span{
              transition: all .3s;
              margin-left: 15/@maxw;
              animation: pageYs .5s ease;
              font-size: 26/@maxw;
            }
            em{
              float: right;
              font-style: normal;
              margin-right: 14/@maxw;
              transition: all .3s;
                display: block;
                width: 11/@maxw;
                height: 19/@maxw;
                margin-top: 26/@maxw;
                background-image: url("../../assets/left_icon.png");
                background-repeat: no-repeat;
                background-size: 100% 100%;
            }
            div{
              padding: 10/@maxw 0 0 0;
              width: 100%;
              margin: 0 0 0 -10/@maxw; 
              // 样式注释，先别删
              // .twoMenu{
              //   position: relative;
              //   right: 0;
              //   display: flex;
              //   flex-direction: column;
              //   align-items: flex-end;
              //   padding: 0;
              //   width: 100%;
              //   line-height: 25/@maxw;
              //   transition: all .3s;
              //   font-size: 20/@maxw;
              //   font-family: Source Han Sans CN-Medium;
              //   font-weight: 500;
              //   >span{
              //     font-size: 23/@maxw;
              //   }
              // }
              .twoMenu{
                position: relative;
                right: 0;
                // display: flex;
                // flex-direction: column;
                // align-items: flex-end;
                // padding: 8px;
                width: 100%;
                // line-height: 25/@maxw;
                // line-height: 12/@maxw;
                line-height: 12/@maxw;
                transition: all .3s;
                font-size: 20/@maxw;
                font-family: Source Han Sans CN-Medium;
                font-weight: 500;
                text-overflow: ellipsis;
                white-space: nowrap;
                // overflow: hidden;
                text-align: right;
                // background-color: rebeccapurple;
                >span{
                  font-size: 23/@maxw;
                }
              }
            }
          }
          >li:last-child{
            // background-image: url("");
            opacity: 1;
          }
          >li:hover{
            >span{
              color: #174994;
            }
            >a{
              opacity: 1 !important;
            } 
          }
        }
      }
      .left_nav::after{
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        width: 382/@maxw;
        height: 686/@maxw;
        background-image: url("../../assets/erjimenubackground.png");
        background-size: 100% 100%;
      }
      .right_nav{
        // word-break: break-all;
        word-break:break-word;
        box-sizing: border-box;
        padding: 0 25/@maxw 0 0;
        width: 1130/@maxw;
        margin-top: 45/@maxw;
        font-size: 20/@maxw;
        // 给富文本里面的a链接设置hover效果
        /deep/ a:hover{ 
          text-decoration: revert !important;
        } 
        /deep/ img{
          max-width: 100%;
        }
        /deep/ video{
          max-width: 100%;
          object-fit: cover;
        }
      }
    }
  }
}
// 判断如果某个二级标题太长溢出隐藏
.twoMenu_overflow{
  position: relative;
  right: 0;
  width: 100%;
  line-height: 12/@maxw;
  transition: all .3s;
  font-size: 20/@maxw;
  font-family: Source Han Sans CN-Medium;
  font-weight: 500;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  text-align: right;
}
// 动画函数
@keyframes pageYs {
  from{
    transform: translateY(800/@maxw);
    opacity: 0;
  }
  to{
    transform: translateY(0);
    opacity: 1;
  }
}
//  路由动画
.fade-enter-active, .fade-leave-active {
  transition: opacity 1s;
}
.fade-enter-to {
  animation: pageYs 1s ease;
}

// 面包屑动画
.breadcrumb span {
  animation: pageYs .5s ease;
  font-size: 22/@maxw;
  font-family: Source Han Sans CN-Medium;
  font-weight: 500;
  color: #FFFFFF;
}
.breadcrumb{
  position: absolute;
  right: -15px;
}
.spans{ 
  margin-top: 23/@maxw !important;
  animation: pageYs .5s ease;
  font-family: fantBold;
  font-weight: 300 !important;
 }
 .footer{
   margin-top: 185px;
 }
.phoneRouterview{
  display: none;
    background-color: #fff;
}
 @media screen and (max-width:750px) {
@maxw_:750/100vw;
  .ac_box{
    display: none !important;
  }
  .phoneRouterview{
    padding: 0;
    margin: 0;
    width: 750/@maxw_ !important;
    height: 100% !important;
    display: block;
    background-color: #fff;
  }
  .article_bacimg{
    width: 750/@maxw_ !important;
    height: 255/@maxw_;
    margin-top: 96/@maxw_;
    img{
      width: 100%;
      height: 100%;
    }
  }
  .navphone{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0;
    width: 100%;
    height: 80/@maxw_;
    background: linear-gradient(to right, #FDF9EB 0%, #AF9680 100%);
    .leftImg{
      display: flex;
      justify-content: center;
      align-items: center;
      width: 251/@maxw_;
      height: 100%;
      background-image: url('../../assets/phoneartic_xq.png');
      background-size: 100% 100%;
      background-repeat: no-repeat;
      >span{
        color: #fff;
        font-size: 32/@maxw_;
        font-family: Source Han Sans CN-Medium;
        font-weight: 500;
      }
    }
    .rightText{
      margin-right: 37/@maxw_;
      font-size: 18/@maxw_;
      color: #fff;
    }
  }
  .routerNav{
    margin: 50/@maxw_ 0 0 0 !important;
    padding: 0 30/@maxw_ !important;
    word-break: break-word;

    // 给富文本里面的a链接设置hover效果
        /deep/ a:hover{ 
          text-decoration: revert !important;
        } 
        /deep/ img{
          max-width: 100%;
          max-height: 500px;
          height: auto;
        }
        /deep/ video{
          max-width: 100%;
          object-fit: cover;
        }
        /deep/ table{
          max-width: 100%;
          overflow: auto;
        }
  }
}
</style>
<!-- onmouseup -->